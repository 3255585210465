





























import {Component, Mixins} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import {mapGetters, mapMutations} from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {formatDate} from "@/lib/date";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";
import SimpleButton from "@/components/SimpleButton.vue";

@Component({
  components: { Page, SimpleButton },
  computed: {
    ...mapGetters({contractId: "contract/id"}),
    ...mapGetters({notificationsList: "notifications/notificationsList"}),
    currentNotificationText() {
      return this.currentNotification?.текст || "";
    },
    currentNotificationDate() {
      let date = this.currentNotification?.датавремя || "";
      if (date != "") {
        date = "Уведомление от " + formatDate(date, "full");
      }
      return date;
    },
    currentNotificationFiles() {
      return (this.currentNotification != undefined && this.currentNotification['@естьвложения'] == 1);
    },
  },
  methods: {
    ...mapMutations("error", { setShow: "setShow", setMessage: "setMessage" }),
  },
  data() {
    return {
      currentNotification: null
    }
  }
})
class Notifications extends Mixins(AppApiMixin, DocGetterMixin) {
  [x: string]: any;

  downloadLoading: boolean = false;

  async mounted() {
    const notificationId = parseInt(this.$route.params.notificationId);
    if (notificationId > 0) {
      this.currentNotification = this.notificationsList.filter(item => item['$номерЗаписи'] == notificationId).shift();
      // пометка прочитанным
      if (this.currentNotification.статус == 2) {
        this.readNotification(notificationId, (data) => {
          if (data.result) {
            this.fetchNotifications(this.contractId);
          }
        });
      }
    }
  }

  // получение файлов
  getFiles() {
    this.downloadLoading = true;
    this.getNotificationFiles(this.currentNotification['$номерЗаписи'])
      .then((response) => {
        this.downloadDocument(response);
      })
      .catch((_) => {
        this.setMessage("Ошибка скачивания файла");
        this.setShow(true);
      })
      .finally(() => (this.downloadLoading = false));
  }
}

export default Notifications;
